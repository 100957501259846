import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router, } from "@angular/router";
import { AppConfigService } from 'src/app/_services/app-config.service';
export class RequestcallbackComponent {
    constructor(router, route, formBuilder, _http, storageService) {
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this._http = _http;
        this.storageService = storageService;
        this.apiurl = AppConfigService.settings.apiServer.apiurl;
        this.submitted = false;
        this.validationAnimate = false;
        this.ispreloader = false;
        this.isSuccess = false;
        this.leadFormMessage = AppConfigService.settings.Labels['leadFormMessage'];
        this.leadFormTitle = AppConfigService.settings.Labels['leadFormTitle'];
        this.leadFormSuccesMessage = AppConfigService.settings.Labels['leadFormSuccesMessage'];
    }
    ngOnInit() {
        this.leadform = this.formBuilder.group({
            first_name: ["", Validators.required],
            phone_number: ["", [Validators.required, Validators.pattern("[0-9]{10,12}")]],
            email: ["", [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        });
    }
    get f() {
        return this.leadform.controls;
    }
    submitform($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.submitted = true;
            this.validationAnimate = true;
            setTimeout(() => {
                this.validationAnimate = false;
            }, 400);
            if (!this.leadform.invalid) {
                var url = this.apiurl + "leads/";
                var formData = new FormData();
                var postpara = {};
                Object.keys(this.leadform.controls).forEach((key) => {
                    postpara[key] = this.f[key].value;
                    formData.append(key, this.f[key].value);
                });
                this.ispreloader = true;
                var appres = yield this._http.post(url, formData).toPromise();
                this.ispreloader = false;
                if (appres["status"] && appres["status"] == 'ok') {
                    this.isSuccess = true;
                    var myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'));
                    myModal.show();
                }
                setTimeout(() => {
                    this.submitted = false;
                    this.leadform.reset();
                }, 1000);
                setTimeout(() => {
                    this.isSuccess = false;
                }, 1500);
            }
        });
    }
}
