import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "../_services/app-config.service";
import * as i0 from "@angular/core";
import * as i1 from "../_services/storage.service";
import * as i2 from "@angular/router";
import * as i3 from "../_services/clinic.service";
import * as i4 from "@angular/common/http";
export class SuccessAppointment {
    constructor(storageService, router, cs, http) {
        this.storageService = storageService;
        this.router = router;
        this.cs = cs;
        this.http = http;
        this.initclinik = AppConfigService.settings.clinic.name;
        this.initpk = AppConfigService.settings.clinic.pk;
        this.apiurl = AppConfigService.settings.apiServer.apiurl;
    }
    resolve(route, state) {
        if (route["params"]["pk"]) {
            console.log(route["params"]["pk"]);
            var appointmentKey = route["params"]["pk"];
            return this.cs.getsingleApp(appointmentKey);
        }
        return {};
    }
}
SuccessAppointment.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuccessAppointment_Factory() { return new SuccessAppointment(i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.ClinicService), i0.ɵɵinject(i4.HttpClient)); }, token: SuccessAppointment, providedIn: "root" });
