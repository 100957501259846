import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "../_services/app-config.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "../_services/clinic.service";
import * as i4 from "../_services/storage.service";
export class OrderstatusR {
    constructor(router, http, cs, storageService) {
        this.router = router;
        this.http = http;
        this.cs = cs;
        this.storageService = storageService;
        this.initclinik = AppConfigService.settings.clinic.name;
        this.initpk = AppConfigService.settings.clinic.pk;
    }
    resolve(route, state) {
        var repdata = {};
        if (route["queryParams"]["payment_status"]) {
            if (route["queryParams"]["payment_status"] == "Credit") {
                var Appdata = {};
                //console.log(route["queryParams"]);
                repdata["status"] = "Success";
                repdata["appdatas"] = this.cs.updateingleApp(route["queryParams"]["pk"], route["queryParams"]["payment_id"], route["queryParams"]["payment_request_id"]);
                return repdata;
            }
        }
        repdata["status"] = "Failed";
        repdata["appdata"] = this.cs.getsingleApp(route["queryParams"]["pk"]);
        return repdata;
    }
}
OrderstatusR.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderstatusR_Factory() { return new OrderstatusR(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.ClinicService), i0.ɵɵinject(i4.StorageService)); }, token: OrderstatusR, providedIn: "root" });
