import * as tslib_1 from "tslib";
import { HttpClient, } from "@angular/common/http";
import { Observable, } from "rxjs";
import { AppConfigService } from "./app-config.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ClinicService {
    constructor(http) {
        this.http = http;
        this.apiurl = AppConfigService.settings.apiServer.apiurl;
    }
    getservices(AppointmentTypeQuery) {
        var url = this.apiurl +
            "appointment/autoservicetype/?filter=" +
            AppointmentTypeQuery;
        /*var urlP = this.apiurl + "appointment/practitioners/";*/
        const response1 = this.http.get(url);
        /*	const response2 = this.http.get(urlP);*/
        return response1;
    }
    updateingleApp(pk, payment_status, payment_request_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var url = this.apiurl + "appointment/appstatus/";
            var formData = new FormData();
            formData.append("payment_id", payment_status);
            formData.append("payment_request_id", payment_request_id);
            formData.append("pkey", pk);
            return yield this.http.post(url, formData).toPromise();
        });
    }
    getsingleApp(pk) {
        var url = this.apiurl + "appointment/singleapp/" + pk;
        return this.http.get(url);
    }
}
ClinicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClinicService_Factory() { return new ClinicService(i0.ɵɵinject(i1.HttpClient)); }, token: ClinicService, providedIn: "root" });
