import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable, of, EMPTY, from, Subject } from "rxjs";
import { mergeMap, take, catchError, map } from "rxjs/operators";
import xml2js from "xml2js";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ClinicService } from "../_services/clinic.service";
import { AppConfigService } from "../_services/app-config.service";
import { StorageService } from "../_services/storage.service";
import { PractitionerService } from "../_services/practitioner.service";
@Injectable({
  providedIn: "root",
})
export class SuccessAppointment implements Resolve<any> {
  initclinik = AppConfigService.settings.clinic.name;
  initpk = AppConfigService.settings.clinic.pk;
  apiurl = AppConfigService.settings.apiServer.apiurl;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private cs: ClinicService,
    private http: HttpClient
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route["params"]["pk"]) {
      console.log(route["params"]["pk"]);
      var appointmentKey = route["params"]["pk"];
      return this.cs.getsingleApp(appointmentKey);
    }
    return {};
  }
}
