import { Routes } from "@angular/router";
import { Initlist } from "./_resolver/initlist.resolve";
import { OrderstatusR } from "./_resolver/orderstatus.resolve";
import { SuccessAppointment } from "./_resolver/success.resolve";
import { OrderstatusComponent } from "./pages/orderstatus/orderstatus.component";
import { CompletedComponent } from "./pages/completed/completed.component";
import { RequestcallbackComponent } from './pages/requestcallback/requestcallback.component';
const ɵ0 = () => import("./pages/steps/steps.module.ngfactory").then(m => m.StepsModuleNgFactory), ɵ1 = SuccessAppointment, ɵ2 = { animation: 5 }, ɵ3 = { animation: 6 }, ɵ4 = { animation: 7 }, ɵ5 = { animation: 8 };
const routes = [
    {
        path: "",
        loadChildren: ɵ0
    },
    {
        path: "success/:pk",
        component: CompletedComponent,
        resolve: {
            data: ɵ1,
        },
        data: ɵ2
    },
    {
        path: "order/success",
        component: OrderstatusComponent,
        resolve: { status: OrderstatusR },
        data: ɵ3
    },
    {
        path: "order/failed",
        component: OrderstatusComponent,
        resolve: { list: Initlist, status: OrderstatusR },
        data: ɵ4
    },
    {
        path: "callback",
        component: RequestcallbackComponent,
        data: ɵ5
    },
    { path: "**", redirectTo: "" },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
