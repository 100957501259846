import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import {
  ActivatedRoute,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { AppConfigService } from 'src/app/_services/app-config.service';
import { StorageService } from 'src/app/_services/storage.service';
declare var bootstrap;

@Component({
  selector: 'app-requestcallback',
  templateUrl: './requestcallback.component.html',
  styleUrls: ['./requestcallback.component.scss']
})
export class RequestcallbackComponent implements OnInit {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  leadform: FormGroup;
  submitted: boolean = false;
  validationAnimate:boolean=false;
  ispreloader:boolean=false;
  isSuccess:boolean=false;
  leadFormMessage=AppConfigService.settings.Labels['leadFormMessage'];
  leadFormTitle=AppConfigService.settings.Labels['leadFormTitle'];
  leadFormSuccesMessage=AppConfigService.settings.Labels['leadFormSuccesMessage'];
  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private formBuilder: FormBuilder,
    private _http: HttpClient,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.leadform = this.formBuilder.group({
      first_name: ["", Validators.required], 
      phone_number: ["", [Validators.required,Validators.pattern("[0-9]{10,12}")]],
      email: ["", [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      
    });
  }
  get f() {
    return this.leadform.controls;
  }
  async submitform($event)  {
    this.submitted = true;
    
    this.validationAnimate=true;
    setTimeout(()=>{
      this.validationAnimate = false;
    },400);
    if (!this.leadform.invalid) {
      var url = this.apiurl + "leads/";
      var formData: any = new FormData();
      var postpara = {};
      Object.keys(this.leadform.controls).forEach((key) => {
        postpara[key] = this.f[key].value;
        formData.append(key, this.f[key].value);
      });
this.ispreloader=true;
      var appres = await this._http.post<any>(url, formData).toPromise();
      this.ispreloader=false;
      if(appres["status"] && appres["status"]=='ok'){
this.isSuccess=true;
var myModal = new bootstrap.Modal(document.getElementById('staticBackdrop'));
myModal.show();
      }


      setTimeout(()=>{
        this.submitted = false;
        this.leadform.reset();
 
      },1000);
      setTimeout(()=>{
       
        this.isSuccess=false;
      },1500);

    }

  }

}
