import { Component, OnInit } from "@angular/core";

import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ActivatedRoute,
  ActivationEnd,
  Router,
  RouterEvent,
  RouterOutlet,
} from "@angular/router";
import { AppConfigService } from "./_services/app-config.service";
declare let gtag:Function;
declare let fbq:Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"] 
})
export class AppComponent implements OnInit {
  CurrentYear=new Date().getFullYear();
  isdisplaypreloder:boolean=false;
  mainTitle:string=AppConfigService.settings.Labels["MainTitle"];
  AvalMessage:string=AppConfigService.settings.Labels["AvalMessage"];
  constructor(
    private router: Router,
    private route: ActivatedRoute, 
  ) {
    router.events.subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart){
        var currenturl=routerEvent.url.split('?')[0];
        if(currenturl=="/order/success")   this.isdisplaypreloder=true;       
      }
       
    });
  }
  ngOnInit(): void {
    
  }
}
